<div class="row">
    <div class="col-12 mt-3 mb-2">
        <div class="d-flex justify-content-center" *ngIf="!pageloading ;else PageNotLoaded">
            <ng-container *ngIf="pageType=='homepage' ;else NoHomePage">
                <div *ngIf="leaderBoardAdvImages.length > 0 && isFastNetwork; else NoleaderBoard">
                    <div class="carousel-container">
                        <div class="carousel-inner">
                            <div *ngFor="let images of leaderBoardAdvImages; let i = index" class="carousel-item"
                                [class.active]="currentIndex === i" [class.slide-in]="currentIndex === i">

                                <a *ngIf="currentIndex === i" class="advertisement-box m-2" [href]="images.url"
                                    target="_blank">
                                    <img [src]="images.path" class="img-fluid sec-adv-img" alt="Advertisement">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-template #NoleaderBoard>
                    <div *ngIf="!isFastNetwork ; else NoleaderBoardContent">
                        <div *ngFor="let images of leaderBoardAdvImages;let i=index">
                            <a target="_blank" *ngIf="i==0" class="advertisement-box m-2" href="{{images.url}}">
                                <img [src]="images.path" class="img-fluid sec-adv-img">
                            </a>
                        </div>
                    </div>
                    <ng-template #NoleaderBoardContent>
                        <div>
                            <a target="_blank" class="advertisement-box m-2 box-width">
                                <h3 class="advertisement text-center mb-0">Super Leaderboard
                                </h3>
                                <p class="text-center advertisement-content ">990 x 90</p>
                            </a>
                        </div>
                    </ng-template>
                </ng-template>
            </ng-container>

            <ng-template #NoHomePage>
                <ng-container
                    *ngIf="pageType === 'listpage' || pageType === 'allpage' && isFastNetwork ;else SlowNetworkData">
                    <div class="d-flex justify-content-center">
                        <div class="carousel-container">
                            <div class="carousel-inner">
                                <div *ngFor="let images of leaderBoardAdvImages; let i = index" class="carousel-item"
                                    [class.active]="currentIndex === i" [class.slide-in]="currentIndex === i">
    
                                    <a *ngIf="currentIndex === i" class="advertisement-box m-2" [href]="images.url"
                                        target="_blank">
                                        <img [src]="images.path" class="img-fluid sec-adv-img" alt="Advertisement">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </ng-container>

            </ng-template>
            <ng-template #SlowNetworkData>
                <div *ngFor="let images of leaderBoardAdvImages;let i=index">
                    <a target="_blank" *ngIf="i==1" class="advertisement-box m-2" href="{{images.url}}">
                        <img [src]="images.path" class="img-fluid sec-adv-img">
                    </a>
                </div>
            </ng-template>
        </div>

        <ng-template #PageNotLoaded>
            <div class="d-flex justify-content-center">
                <div class="advertisement-box m-2 skeleton box-width">
                    <!-- <h3 class="advertisement text-center mb-0">Leaderboard
                </h3>
                <p class="text-center advertisement-content ">728 x 90</p> -->
                </div>
            </div>
        </ng-template>
    </div>
</div>