import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { GeneralRoutingModule } from './general-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SearchComponent } from '../general/search/search.component';
import { PaginationComponent } from '../general/pagination/pagination.component';
import { BillboardComponent } from './advertisement/billboard/billboard.component';
import { BjsAdComponent } from './advertisement/bjs-ad/bjs-ad.component';
import { LeaderBoardComponent } from './advertisement/leader-board/leader-board.component';
import { PortraitComponent } from './advertisement/portrait/portrait.component';
import { CategoryComponent } from './category/category.component';
import { BannerComponent } from './banner/banner.component';
import { ArticleCardListComponent } from './article-card-list/article-card-list.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HomeBannerComponent } from './home-banner/home-banner.component';
import { ModalComponent } from './modal/modal.component';

@NgModule({
  declarations: [
    SearchComponent,
    PaginationComponent,
    BillboardComponent,
    BjsAdComponent,
    LeaderBoardComponent,
    PortraitComponent,
    CategoryComponent,
    BannerComponent,
    ArticleCardListComponent,
    PageNotFoundComponent,
    HomeBannerComponent,
    ModalComponent,
  ],
  imports: [
    CommonModule,
    GeneralRoutingModule,
    ReactiveFormsModule,
    FontAwesomeModule,
  ],
  exports: [
    SearchComponent,
    PaginationComponent,
    BillboardComponent,
    BjsAdComponent,
    LeaderBoardComponent,
    PortraitComponent,
    CategoryComponent,
    BannerComponent,
    ArticleCardListComponent,
    PageNotFoundComponent,
    HomeBannerComponent,
    ModalComponent,
  ],
  providers:[
    DatePipe
  ]
})
export class GeneralModule {}
