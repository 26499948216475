import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-portrait',
  templateUrl: './portrait.component.html',
  styleUrl: './portrait.component.scss'
})
export class PortraitComponent {
  @Input() pageloading: boolean;
  advertImagePath = 'adverts/Portrait/'
  portraitAdvImages: any[] = [
    {
      path: this.advertImagePath + '300 x 1050 EHS Advanced MIS.png',
      url: 'https://europeanherniasociety.eu/news/ehs-advanced-mis-cadaveric-hernia-masterclass-2/',
      remove_date: '2024-11-15 23:59:59'
    },
    {
      path: this.advertImagePath + '300 x 1050 EHS Intermediate.png',
      url: 'https://europeanherniasociety.eu/events/ehs-intermediate-cadaveric-hernia-masterclass-4/',
      remove_date: '2024-11-22 23:59:59'
    }
  ];
  currentIndex: number = 0;
  interval: any;
  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.currentIndex = this.portraitAdvImages.findIndex(image => this.shouldShowImage(image));
      this.interval = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.portraitAdvImages.length;
        this.portraitAdvImages = this.portraitAdvImages.filter(image => this.shouldShowImage(image));
      }, 7000);
    }
  }
  shouldShowImage(image: any): boolean {
    if (!image.remove_date) {
      return true; // Show the image if remove_date is null
    }

    const removeDate = new Date(image.remove_date);
    const now = new Date();

    return now <= removeDate; // Show the image only if the current date is before or equal to the remove date
  }
}
