import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterServiceStateService {

  private filterState = new BehaviorSubject<{ surgical_speciality: string[], content_type: string[] }>({
    surgical_speciality: [],
    content_type: []
  });
  private loadFilterState = new BehaviorSubject<boolean>(false); // New BehaviorSubject for load_filter

  getFilterState(): Observable<{ surgical_speciality: string[], content_type: string[] }> {
    return this.filterState.asObservable();
  }

  setFilterState(filters: { surgical_speciality: string[], content_type: string[] }) {
    this.filterState.next(filters);
  }
   // Get load filter state
   getLoadFilterState(): Observable<boolean> {
    return this.loadFilterState.asObservable();
  }

  // Set load filter state
  setLoadFilterState(state: boolean) {
    this.loadFilterState.next(state);
  }
}
