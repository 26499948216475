<!-- <div class="card advertisement-1 d-flex" >
   
    <img src="adverts/Portrait/300 x 1050 EHS Advanced MIS.png" class="img-fluid sec-adv-img">
</div> -->
<ng-container *ngIf="!pageloading else PageNotLoaded">
    <div class="carousel-container">
        <div class="carousel-inner">
          <div *ngFor="let images of portraitAdvImages;let i=index"
              class="carousel-item"
              [class.active]="currentIndex === i"
              [class.slide-in]="currentIndex === i">
            
            <a *ngIf="currentIndex === i" class="card advertisement-1 d-flex" [href]="images.url" target="_blank">
              <img [src]="images.path" class="img-fluid sec-adv-img" alt="Advertisement">
            </a>
          </div>
        </div>
      </div>
    <!-- <div *ngFor="let images of portraitAdvImages;let i=index">
        <a target="_blank" *ngIf="currentIndex == i" class="card advertisement-1 d-flex" href="{{images.url}}">
            <img [src]="images.path" class="img-fluid sec-adv-img">
          </a>
    </div> -->
</ng-container>

<ng-template #PageNotLoaded>
    <div class="card advertisement-1 d-flex skeleton" >
      
    </div>
</ng-template>
