import { Component, EventEmitter, Output, OnDestroy, HostListener, Input, ChangeDetectorRef, ElementRef, ViewChild, OnInit, PLATFORM_ID, AfterViewInit, inject, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ArticleService } from '../../services/article.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml, platformBrowser } from '@angular/platform-browser';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { SearchContextService } from '../../services/search-context-service';
import { SharedService } from '../../services/shared.service';
import { isPlatformBrowser } from '@angular/common';
import { FilterServiceStateService } from '../../filter-service-state.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  @ViewChild('searchFormDiv') searchFormDiv: ElementRef;
  @ViewChild('clearBtn') clearBtn: ElementRef
  @ViewChild('filterCard') filterCard!: ElementRef | undefined;
  @ViewChild('filterContainer') filterContainer!: ElementRef | undefined;
  searchForm: FormGroup;
  @Output() searchEvent = new EventEmitter<string>();
  @Output() allcategoryEvent = new EventEmitter<string>();
  @Input() articles: any[]; // Define the appropriate type for your articles

  @Input() category: string = '';// Add this line to accept category from parent
  @Input() allcategory: any;
  @Input() contentType: any;
  @Input() surgicalSpecialty: any;
  @Input() pageType: any;

  showSearchSuggesion = false;
  ShowTextLoader: boolean;
  searchResults: any[] = [];
  lastPart: string = '';/*  */
  private routeSubscription: Subscription;
  firstPart: string;
  search: boolean = false;
  page: string;
  private readonly searchContextService = inject(SearchContextService);
  length = 10;
  start = 0;
  journalid: string = '';
  isInitialLoad: boolean = true; // New flag for initial load
  resultBoxwidth = 0;
  clearBtnWidth = 0;
  allarticles: boolean = false;
  load_filter = false;
  filter_total_count: number;
  uniqueSurgicalSpecialities: string[] = [];
  uniqueContentTypes: string[] = [];
  sub_category: any;
  contentTypeView = true;
  surgicalView = true;
  filteredCategories: any[] = [];
  mainCategories: any[] = [];  // Parent categories
  subCategories: any[] = [];
  filter: boolean;
  constructor(
    private fb: FormBuilder,
    private articleService: ArticleService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private sharedService: SharedService,
    private filterService: FilterServiceStateService,
    private filterStateService: FilterServiceStateService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.searchForm = this.fb.group({
      searchValue: ['']
    });

  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.setPaddingToFilterBox()

  }
  ngOnInit() {

    // if (this.allcategory && this.allcategory.length > 0) {
    //   this.mainCategories = this.allcategory.filter((category: { parent: string; }) => category.parent !== 'scientific-surgery');
    //   this.subCategories = this.allcategory.filter((category: { parent: string; }) => category.parent === 'scientific-surgery');
    //   console.log(this.mainCategories);
    //   console.log(this.subCategories)
    // } else {
    //   // Handle the case where allcategory is undefined or empty
    //   this.mainCategories = [];
    //   this.subCategories = [];
    // }
    if (this.allcategory && this.allcategory.length > 0) {
      // Filter out non-object elements first
      const validCategories = this.allcategory.filter((category: { parent: any; }) => typeof category === 'object' && category.parent);

      // Now filter based on 'parent' property
      this.mainCategories = validCategories.filter((category: { parent: string }) => category.parent !== 'scientific-surgery');
      this.subCategories = validCategories.filter((category: { parent: string }) => category.parent === 'scientific-surgery');


    } else {
      // Handle the case where allcategory is undefined or empty
      this.mainCategories = [];
      this.subCategories = [];
    }


    this.category = this.category || "All";
    this.journalid = this.sharedService.getJournalId();
    this.searchForm.get('searchValue')?.setValue('');
    this.searchResults = [];
    this.search = false;
    this.showSearchSuggesion = false;
    this.isInitialLoad = true; // Set to true on initial load

    this.route.queryParams.subscribe(params => {
      const searchValue = params['search'] || '';
      if (searchValue.length > 0) {
        this.searchForm.get('searchValue')?.setValue(searchValue);
        this.search = true;
        this.searchEvent.emit(searchValue);
        this.showSearchSuggestions(searchValue);
        this.showSearchSuggesion = false;
      }
      this.isInitialLoad = false; // Set to false after processing query params
    });

    this.routeSubscription = this.route.url.subscribe(urlSegments => {
      if (urlSegments.length == 3) {
        this.category = urlSegments[0]?.path;
      }
      else if (urlSegments.length > 3) {
        this.category = urlSegments[1]?.path;
      }
      else if (this.category == undefined || this.category == "All") {
        this.category = "all-articles"

      }
      else if (this.category == "all-articles") {
        this.allarticles = true;
      } else if (urlSegments[0]?.path == "all-articles") {
        this.allarticles = true;
      }
      else {
        this.category = this.category;
        this.allarticles = false;
      }
    });
    this.sub_category = this.route.snapshot.params['subcategory'];
    this.articleService.getFilterData().subscribe(
      (result: any) => {
        // this.apiData = result;
        this.uniqueSurgicalSpecialities = result['surgical_speciality']
        this.uniqueContentTypes = this.sub_category ? [] : result['content_type']
        // this.pageLoading = false; 
      },
      error => {
        //console.error('An error occurred:', error);
      }
    );
    this.clearFilters();
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams['speciality']) {
        const filterValue = queryParams['speciality'].split(',').map((filter: string) => filter.trim());
        if(filterValue){
          this.filter=true;
        }
        this.searchContextService.setSelectedSpecialities(filterValue);
        const specialityCount = this.surgical_speciality_count;
        const contentTypeCount = this.content_type_count;
        this.filter_total_count = specialityCount + contentTypeCount;
      }

      if (queryParams['contentType']) {
        const contentType = queryParams['contentType'].split(',').map((filter: string) => filter.trim());
        if(contentType){
          this.filter=true;
        }
        this.searchContextService.setSelectedContentType(contentType);
        const specialityCount = this.surgical_speciality_count;
        const contentTypeCount = this.content_type_count;

        this.filter_total_count = specialityCount + contentTypeCount;
      }

      //  if(this.journalid)
      //  {
      //   this.filterDataApi(this.journalid);

      //  }
    });

  }
  ngAfterViewInit(): void {
    this.resultBoxwidth = this.searchFormDiv?.nativeElement.offsetWidth;
    this.clearBtnWidth = this.clearBtn?.nativeElement.offsetWidth;
    this.cdr.detectChanges();
  }
  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
  @HostListener('window:resize', [])
  onWindowResizel(): void {
    this.resultBoxwidth = this.searchFormDiv?.nativeElement.offsetWidth;
    this.clearBtnWidth = this.clearBtn?.nativeElement.offsetWidth;
  }
  resetSearch() {
    this.searchForm.get('searchValue')?.setValue('');
    this.showSearchSuggesion = false;
    this.searchResults = [];
    this.searchEvent.emit('');
  }

  onEnterPressed(event: any) {
    const searchValue = this.searchForm.get('searchValue')?.value || '';
    this.searchForm.get('searchValue')?.setValue(searchValue); // Prevents emitting a valueChanges event

    if (searchValue.length >= 3) {
      this.searchArticles(event); // Pass the event here
      this.searchContextService.setSearchContent(searchValue);
    }
  }
  searchArticles(event: any) {
    event.preventDefault();
    this.showSearchSuggesion = false;
    const searchValue = this.searchForm.get('searchValue')?.value || '';
    this.searchContextService.setSearchContent(searchValue);

    if (searchValue.length >= 3) {
      this.showSearchSuggesion = false; // Hide suggestions before navigation

      if (this.allarticles == true) {
        this.router.navigate(['/bjs-academy', 'all-articles', 'page', '1'], {
          queryParams: {
            search: searchValue,
            speciality: this.searchContextService.selectedSpecialities().join(','),
            contentType: this.searchContextService.selectedContentType().join(',')
          }
        });
        this.searchEvent.emit(searchValue);

      }
      else if (this.pageType == "homepage" && this.category != "all-articles") {
        this.router.navigate(['/bjs-academy', this.category, 'page', '1'], {
          queryParams: {
            search: searchValue,
            speciality: this.searchContextService.selectedSpecialities().join(','),
            contentType: this.searchContextService.selectedContentType().join(',')
          }
        }
        );
        // this.searchEvent.emit(searchValue);

      }
      else if (this.pageType == "listpage" && this.category != "all-articles") {

        this.router.navigate(['/bjs-academy', this.category, 'page', '1'], { queryParams: { search: searchValue } });
        // this.searchEvent.emit(searchValue);

      }
      else {
        this.router.navigate(['/bjs-academy', 'all-articles', 'page', '1'], {
          queryParams: {
            search: searchValue,
            speciality: this.searchContextService.selectedSpecialities().join(','),
            contentType: this.searchContextService.selectedContentType().join(',')
          }
        });

      }



      this.searchForm.get('searchValue')?.setValue(searchValue);
      this.searchEvent.emit(searchValue);
      this.showSearchSuggesion = false; // Hide suggestions after navigation (if needed)

    }
    else if (this.category === 'all-articles' && !searchValue) {
      this.router.navigate(['/bjs-academy', 'all-articles', 'page', '1'], {
        queryParams: {
          search: searchValue,
          speciality: this.searchContextService.selectedSpecialities().join(','),
          contentType: this.searchContextService.selectedContentType().join(',')
        }
      });
    }
    else if (this.category && !searchValue) {
      this.router.navigate(['/bjs-academy', this.category, 'page', '1'], {
        queryParams: {
          search: searchValue,
          speciality: this.searchContextService.selectedSpecialities().join(','),
          contentType: this.searchContextService.selectedContentType().join(',')
        }
      });
    }
    else {
      this.search = false;
      this.showSearchSuggesion = false;
    }
  }



  showSearchSuggestions(event: Event) {

    const input = event.target as HTMLInputElement;
    const searchValue = this.searchForm.get('searchValue')?.value || '';;
    this.searchContextService.setSearchContent(searchValue);

    if (searchValue.length === 0) {
      this.showSearchSuggesion = false;
      this.searchResults = [];
      this.searchEvent.emit('');
    }
    else if (searchValue.length == 1) {
      this.search = true; // Show loader
      // if (this.category) {
      //   this.filterDataApi(this.journalid)
      // }
    }
    else if (searchValue.length >= 3) {
      this.ShowTextLoader = true;
      this.search = true; // Show loader
      if (this.category) {
        this.filterDataApi(this.journalid, event)
        this.showSearchSuggesion = false;

      }
    }
    else {
      this.search = false;

    }
  }

  filterDataApi(journalid: string, event: any): void {
    const content_type = this.searchContextService.selectedContentType();
    const surgical_speciality = this.searchContextService.selectedSpecialities();

    const searchValue = this.searchContextService.searchContent()

    const params: any = {
      category: this.category,
      content_type: JSON.stringify(content_type),
      surgical_speciality: JSON.stringify(surgical_speciality),
      searchValue: searchValue
    };

    const articleSub = this.articleService.getArticleList(journalid, params).subscribe(result => {
      this.ShowTextLoader = false;
      this.searchResults = result.data;
      this.route.queryParams.subscribe(params => {
        const searchValue = params['search'] || '';
        if (searchValue && event.inputType != "insertText") {
          this.showSearchSuggesion = false;

        }
        else {
          this.showSearchSuggesion = true;

        }
        this.searchEvent.emit(searchValue);
      });
    },
      (error) => {
        this.ShowTextLoader = false;
      })
  }
  onTextboxClicked(): void {
   
    const searchValue = this.searchForm.get('searchValue')?.value || '';

    if (searchValue.length >= 3) {
      this.showSearchSuggesion = true;

    } else {
      this.showSearchSuggesion = false; // Optionally hide suggestions if less than 3 characters
    }
  }
  highlightMatch(text: string, searchValue: string): SafeHtml {
    if (!text) {
      return this.sanitizer.bypassSecurityTrustHtml('');
    }
    if (!searchValue) {
      return this.sanitizer.bypassSecurityTrustHtml(text);
    }
    const regex = new RegExp(searchValue, 'gi');
    const highlightedText = text.replace(regex, (match) => `<span style="background-color: yellow;">${match}</span>`);
    return this.sanitizer.bypassSecurityTrustHtml(highlightedText);
  }

  formatAuthors(authors: any[]): string {
    return authors.map(author => author).join(', ');
  }

  formatDate(pub_date: any): string {
    if (pub_date && pub_date.preprint) {
      const { day, month, year } = pub_date.preprint;
      if (day && month && year) {
        return `${day} ${this.getMonthName(month)} ${year}`;
      }
    }
    if (pub_date && pub_date.collection) {
      const { day, month, year } = pub_date.collection;
      if (day && month && year) {
        return `${day} ${this.getMonthName(month)} ${year}`;
      }
    }
    return 'Date not available';
  }

  getMonthName(month: number): string {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[month - 1];
  }

  clearSearch() {
    this.searchForm.get('searchValue')?.setValue('');
    this.search = false;
    this.showSearchSuggesion = false;
    // this.router.navigate(['/academy', this.category, 'page', '1']);

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
      replaceUrl: true,
    });
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!(event.target as HTMLElement).closest('.search-result-section') && !(event.target as HTMLElement).closest('.search-form-control')) {
      this.showSearchSuggesion = false;
    }
     const targetElement = event.target as HTMLElement;
    if (this.filterContainer && !this.filterContainer.nativeElement.contains(targetElement) && !targetElement.closest('.filter-btn')) {
      this.load_filter = false; // Close the filter if clicked outside
      // this.clearFilters();

    }
  }

  navigateToArticle(article_link: any, category_heading: any) {
    if (this.category === "all-articles") {
      this.router.navigate(['/bjs-academy', category_heading, article_link]);
    } else {
      this.router.navigate(['/bjs-academy', this.category, article_link]);
    }

  }
  clearFilters() {
    this.searchContextService.setSelectedSpecialities([]);
    this.searchContextService.setSelectedContentType([]);
    this.searchContextService.clearFilters();
    //this.filterService.setCategory([]);
    if (this.searchContextService.selectedSpecialities().length === 0 && this.searchContextService.selectedSpecialities().length === 0) {
      this.filter_total_count = 0;
      // this.router.navigate([],{
      //   queryParams:{}
      // })
    }
 
    // this.filterChange.emit({ surgical_speciality: [], content_type: [] });
    // this.router.navigate([], {       
    //     queryParams: {},        
    // });
  }
  isContentTypeSelected(type: string): boolean {
    return this.searchContextService.selectedContentType().includes(type);
  }
  onSpecialityChange(speciality: string) {
    this.filter = true;
    let selectedSpecialities: string[] = this.searchContextService.selectedSpecialities();

    if (selectedSpecialities.includes(speciality)) {
      selectedSpecialities = selectedSpecialities.filter(s => s !== speciality);
    } else {
      selectedSpecialities.push(speciality);
    }

    this.searchContextService.setSelectedSpecialities(selectedSpecialities);
    this.filterStateService.setFilterState({
      surgical_speciality: this.searchContextService.selectedSpecialities(),
      content_type: this.searchContextService.selectedContentType()
    });
    // Update the query parameters
    // this.router.navigate([], {
    //   relativeTo: this.route,
    //   queryParams: {
    //     speciality: selectedSpecialities.join(','),
    //     contentType: this.searchContextService.selectedContentType().join(',')
    //   },
    //   queryParamsHandling: 'merge' // To preserve existing query params
    // });

    // this.emitFilterChange();
    this.filter_total_count = this.searchContextService.selectedSpecialities().length + this.searchContextService.selectedContentType().length;
  }

  onContentTypeChange(type: string) {
    this.filter = true;
    let selectedContentTypes = this.searchContextService.selectedContentType();

    if (selectedContentTypes.includes(type)) {
      selectedContentTypes = selectedContentTypes.filter(t => t !== type);
    } else {
      selectedContentTypes.push(type);
    }

    this.searchContextService.setSelectedContentType(selectedContentTypes);
    this.filterStateService.setFilterState({
      surgical_speciality: this.searchContextService.selectedSpecialities(),
      content_type: this.searchContextService.selectedContentType()
    });
    this.filter_total_count = this.searchContextService.selectedSpecialities().length + this.searchContextService.selectedContentType().length;
    // Update the query parameters
    // this.router.navigate([], {
    //   relativeTo: this.route,
    //   queryParams: {
    // speciality: this.searchContextService.selectedSpecialities().join(','),
    // contentType: selectedContentTypes.join(',')
    //   },
    //   queryParamsHandling: 'merge' // To preserve existing query params
    // });


    // this.emitFilterChange();

  }
  toggleFilter() {
    this.load_filter=true;
    this.filterService.setLoadFilterState(true); // Open filter
  }
  closeFilter() {
    this.load_filter = false
    this.filterService.setLoadFilterState(false); // Close filter

    // this.surgicalView = false;
    // this.contentTypeView = false;
  }
  isSpecialitySelected(speciality: string): boolean {
    return this.searchContextService.selectedSpecialities().includes(speciality);
  }
  onCategoryChange(event: any) {
    this.filter = true;
    this.category = event.target.value;
    // this.category=event.target.value.toLocaleLowerCase().replace(/ /g, '-');

    //this.allcategoryEvent.emit(event.target.value);
    // You can implement any further logic here based on the selected category
  }
  get surgical_speciality_count(): number {
    const specialities = this.searchContextService.selectedSpecialities();
    return Array.isArray(specialities) ? specialities.length : 0;
  }
  get content_type_count(): number {
    const content = this.searchContextService.selectedContentType();
    return Array.isArray(content) ? content.length : 0;
  }
  setPaddingToFilterBox() {

    if (platformBrowser(this.platformId)) {
      if(this.filterContainer){
        if (this.pageType == 'homepage' && window.scrollY == 0) {
          this.filterContainer!.nativeElement.style.paddingTop = "0rem";
        }
        else if (this.pageType == 'homepage' && window.scrollY <= 700 ) {
          this.filterContainer!.nativeElement.style.paddingTop = "6rem";
  
        }
        else if (this.pageType == 'homepage' && window.scrollY > 700 && this.filterContainer) {
          this.filterContainer!.nativeElement.style.paddingTop = "4rem";
        }
      }
    }
  }
}
