<section id="hero" class="hero">
  <div class="container-fluid ">

    <div #hero class="hero-bg" aria-label="BJS banner image" *ngIf="!pageloading ;else PageNotLoaded"
      >
      <div #bannerCarousel id="carouselExampleCaptions" class="carousel slide" data-bs-ride="true">

        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="home_page_background.png" class="d-block w-100 carousel-image" alt="...">
            <div class="carousel-caption ">
              <h1 class="banner-content text-center" *ngIf="BannerHeadingfirstPart">{{BannerHeadingfirstPart}} </h1>
              <h1 class="banner-content text-center mt-0" *ngIf="BannerHeadinglastPart">{{BannerHeadinglastPart}}</h1>
            </div>
          </div>
          <div class="carousel-item">
            <img src="Academy 3.gif" class="d-block w-100 carousel-image" alt="...">
            <div class="carousel-caption d-none d-md-block">
              <!-- <h5>Second slide label</h5>
              <p>Some representative placeholder content for the second slide.</p> -->
              <!-- <h1 class="banner-content text-center" *ngIf="BannerHeadingfirstPart">{{BannerHeadingfirstPart}} </h1>
              <h1 class="banner-content text-center mt-0" *ngIf="BannerHeadinglastPart">{{BannerHeadinglastPart}}</h1> -->
            </div>
          </div>
          <div class="carousel-item">
            <img src="charity 3.gif" class="d-block w-100 carousel-image" alt="...">
            <div class="carousel-caption d-none d-md-block">
              <!-- <h5>Third slide label</h5>
              <p>Some representative placeholder content for the third slide.</p> -->
              <!-- <h1 class="banner-content text-center" *ngIf="BannerHeadingfirstPart">{{BannerHeadingfirstPart}} </h1>
              <h1 class="banner-content text-center mt-0" *ngIf="BannerHeadinglastPart">{{BannerHeadinglastPart}}</h1> -->
            </div>
          </div>
          <div class="carousel-item">
            <img src="young BJS 3.gif" class="d-block w-100 carousel-image" alt="...">
            <div class="carousel-caption d-none d-md-block">
              <!-- <h5>Third slide label</h5>
              <p>Some representative placeholder content for the third slide.</p> -->
              <!-- <h1 class="banner-content text-center" *ngIf="BannerHeadingfirstPart">{{BannerHeadingfirstPart}} </h1>
              <h1 class="banner-content text-center mt-0" *ngIf="BannerHeadinglastPart">{{BannerHeadinglastPart}}</h1> -->
            </div>
          </div>
        </div>
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
            aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
            aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
            aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3"
            aria-label="Slide 4"></button>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <!-- <div class="row">
        <div class="col" tabindex="0">
          <h1 class="banner-content text-center" *ngIf="BannerHeadingfirstPart">{{BannerHeadingfirstPart}} </h1>
          <h1 class="banner-content text-center mt-0" *ngIf="BannerHeadinglastPart">{{BannerHeadinglastPart}}</h1>
        </div>
      </div> -->


    </div>
    <ng-template #PageNotLoaded>
      <div class="hero-bg skeleton skeleton-banner">

        <!-- <div class="row">
          <div class="col" tabindex="0">
            <h1 class="banner-content text-center" *ngIf="BannerHeadingfirstPart">{{BannerHeadingfirstPart}} </h1>
            <h1 class="banner-content text-center mt-0" *ngIf="BannerHeadinglastPart">{{BannerHeadinglastPart}}</h1>
          </div>
        </div> -->


      </div>
    </ng-template>
    <div id="search" aria-label="Search section" class="search-section-box" #maindiv >
      <div class="row">
        <div class="col">
          <app-category [categoryArray]="categories" [pageType]="pageType" [extraCategory]="extraCategory"
            (searchEvent)="onSearchEvent($event)"></app-category>
        </div>
      </div>
    </div>
    
  </div>
</section>